<template>
  <section class="province-summary">
    <div
      class="d-flex justify-content-between mt-3 mx-2"
      v-if="currentProvince"
    >
      <div>
        <h4 class="font-weight-bold">
          จังหวัด{{ currentProvince.name_th }}
          <span
            @click="showProvinceSelection"
            class="ml-3 font-weight-normal text-normal pointer"
          >
            เลือกจังหวัด <eva-icon name="search-outline"></eva-icon>
          </span>
        </h4>
      </div>
      <div>
        <button @click="viewPlan()" class="btn btn-outline-red mr-2">
          มองในมุมมอง สพจ.
        </button>
        <router-link
          :to="{
            name: 'pso.energy_report',
            params: {
              year: currentNationalPlan.year,
              iso3166_2: currentProvince.iso3166_2,
            },
          }"
          class="btn btn-outline-red"
          >ดูตัวอย่างแผนจังหวัด
        </router-link>
      </div>
    </div>
    <h4
      v-if="!currentProvince"
      @click="showProvinceSelection"
      class="mt-3 ml-3 font-weight-normal text-normal pointer"
    >
      เลือกจังหวัด
      <eva-icon name="search-outline"></eva-icon>
    </h4>

    <vue-element-loading
      v-if="!ready && currentProvince != null && !error"
      :active="!ready && currentProvince != null"
      spinner="spinner"
      color="#ED110E"
      class="position-relative mt-5"
      background-color="transparent"
    />
    <retry v-on:retry="fetchData" v-if="!ready && error"></retry>
    <div
      class="province-content container-fluid mt-3 mb-4"
      v-if="ready"
      id="main_content"
    >
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-6">
              <province-general
                :province="currentProvince"
                :nationalPlan="currentNationalPlan"
              ></province-general>
            </div>
            <div class="col-md-6 d-none d-md-block">
              <div class="border w-100">
                <map-svg
                  :selectedProvinces="[currentProvince]"
                  @onRegionClick="selectProvinceByMap"
                />
              </div>
            </div>
          </div>
          <province-summary-chart
            :province="currentProvince"
            :nationalPlan="currentNationalPlan"
            class="mt-3"
          >
          </province-summary-chart
          ><!-- graph -->
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="box">
                <div class="box-header">ความคืบหน้าในจังหวัด</div>
                <div class="box-body">
                  <div class="row">
                    <div class="col">
                      <div class="text-light-ipepp font-weight-bold">
                        KTOE (EE)
                      </div>
                      <h4 class="">
                        {{ provinceSummary.ktoe_ee }}
                      </h4>
                    </div>
                    <div class="col">
                      <div class="text-light-ipepp font-weight-bold">
                        KTOE (RE)
                      </div>
                      <h4 class="">
                        {{ provinceSummary.ktoe_re }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-2 mt-md-0">
              <div class="box ml-md-0">
                <div class="box-header">งบประมาณ</div>
                <div class="box-body">
                  <div class="row">
                    <div class="col">
                      <div class="text-light-ipepp font-weight-bold">
                        งบประมาณที่ได้รับจัดสรรจริง
                      </div>
                    </div>
                    <div class="col">
                      <div class="text-light-ipepp font-weight-bold">
                        งบประมาณตามแผน
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <h4 class="">
                        {{ provinceSummary.budget_actual | money }}
                        <span class="font-weight-normal small-size">บาท</span>
                      </h4>
                    </div>
                    <div class="col">
                      <h4 class="">
                        {{ provinceSummary.budget_plan | money }}
                        <span class="font-weight-normal small-size">บาท</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col">
              <div class="box">
                <div class="box-header">รายงานความก้าวหน้ารายปี</div>
                <div class="box-body">
                  <div
                    class="mb-4 pb-4 border-bottom"
                    v-for="(project, index) in provinceSummary.projects"
                    v-bind:key="project.id"
                  >
                    <div class="font-weight-bold">
                      โครงการที่ {{ parseInt(index) + 1 }}
                    </div>
                    <div class="text-medium mb-2">
                      {{ project.name }}
                    </div>
                    <div class="d-flex mb-2 align-items-baseline">
                      <div class="font-weight-bold mr-3">วงเงิน</div>
                      <div class="text-medium">
                        {{ project.project_budgets_expected | auto_number }} บาท
                      </div>
                    </div>
                    <div class="d-flex mb-2 align-items-baseline">
                      <div class="font-weight-bold mr-3">แหล่งงบ</div>
                      <ul>
                        <li
                          v-for="source in project.budget_sources"
                          :key="source.type"
                        >
                          <span
                            v-for="listSource in budgetSources"
                            :key="listSource.id"
                          >
                            <span v-if="listSource.id == source.type">
                              {{ listSource.title }}
                              <span v-if="source.other">
                                ({{ source.other }})
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div class="mb-2 align-items-baseline">
                      <div class="font-weight-bold">ผลลัพธ์สำคัญ</div>
                      <div
                        class="text-medium"
                        v-for="target_result in project['target-results'].data"
                        v-bind:key="target_result.id"
                      >
                        {{ projectTargetResultDisplay(target_result) }}
                      </div>
                    </div>
                    <div class="mb-2 align-items-baseline">
                      <div class="font-weight-bold">ตัวชี้วัด</div>
                      <div
                        class="text-medium"
                        v-for="(indicator, index) in project['project_target_indicators'].data"
                        :key="indicator.id"
                      >
                        <div class="font-weight-bold">
                          {{ index + 1 }}.) {{ indicator.target_indicator_name }}
                        </div>
                        <div class="row text-small md:text-medium">
                          <div class="d-flex target-indicator-item">
                            <div class="mr-2 no-wrap">ผลผลิตที่คาดหวัง:</div>
                            <div class="value border-bottom text-center align-items-end">
                              {{ indicator.indicator_value_expected | auto_number(false, 3) }}
                            </div>
                          </div>
                          <div class="d-flex target-indicator-item">
                            <div class="mr-2 no-wrap">ผลผลิตจริง:</div>
                            <div class="value border-bottom text-center align-items-end">
                              {{ indicator.indicator_value_result | auto_number(false, 3) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 pr-md-0 plan-progress">
          <affix
            :enabled="isMd"
            class="box"
            relative-element-selector="#main_content"
            :offset="{ top: 10, bottom: 0 }"
            :scrollAffix="true"
          >
            <province-report-status
              class="pb-4"
              :nationalPlan="currentNationalPlan"
              :province="currentProvince"
            >
            </province-report-status>
          </affix>
        </div>
      </div>
    </div>
    <div v-else class="h-100 d-flex align-items-center justify-content-center">
      <h4 @click="showProvinceSelection" class="pointer">กรุณาเลือกจังหวัด</h4>
    </div>
    <modals-container @onSelectProvince="selectProvince"></modals-container>
  </section>
</template>

<script>
import ProvinceSelection from '@/components/ProvinceSelection.vue';
import ProvinceReportStatus from '@/components/ProvinceReportStatus.vue';
import ProvinceSummaryChart from '@/components/ProvinceSummaryChart.vue';
import Retry from '@/components/Retry.vue';
import MapSvg from '@/components/MapSvg.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import nationalPlanChangedListener from '../../mixins/nationalPlanChangedListener';
import ProvinceGeneral from '@/components/ProvinceGeneral.vue';

export default {
  name: 'ProvinceSummary',

  mixins: [nationalPlanChangedListener],

  components: {
    MapSvg,
    ProvinceReportStatus,
    ProvinceSummaryChart,
    ProvinceGeneral,
    Retry,
  },

  data() {
    return {
      provinceGeneral: null,
      provinceSummary: null,
      version: 'draft',
      created_at: '2018-12-12',
      updated_at: '2019-02-12',
      ready: false,
      error: false,
    };
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapState('pso', ['currentProvince']),
    ...mapState('peno', ['budgetSources']),
    ...mapGetters(['currentNationalPlan', 'getProvinceByISO']),
    isMd() {
      return window.matchMedia('(min-width: 768px)').matches;
    },
  },
  methods: {
    ...mapActions('auth', ['setImpersonate', 'setImpersonatingFrom']),
    ...mapActions('pso', ['fetchProvinceSummary']),
    ...mapActions('mondas', ['fetchProvinceGeneral']),
    showProvinceSelection() {
      this.$modal.show(
        ProvinceSelection,
        {
          name: 'province_selection',
        },
        {
          name: 'province_selection',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '70%',
          transition: 'fade',
        }
      );
    },
    selectProvince(province) {
      this.$store.commit('pso/setCurrentProvince', province);
      this.fetchData();
    },
    projectTargetResultDisplay(targetResult) {
      return (
        targetResult.name + ' ' + targetResult.value + ' ' + targetResult.unit
      );
    },
    fetchData() {
      if (this.currentProvince) {
        this.ready = false;
        this.error = false;
        this.fetchProvinceSummary({
          nationalPlan: this.currentNationalPlan,
          province: this.currentProvince,
        })
          .then((data) => {
            this.provinceSummary = data;
            this.ready = true;
            this.error = false;
          })
          .catch(() => {
            this.ready = false;
            this.error = true;
          });
      }
    },
    selectProvinceByMap(iso3166_2) {
      this.selectProvince(this.getProvinceByISO(iso3166_2));
    },
    currentNationalPlanChanged() {
      this.fetchData();
    },
    viewPlan() {
      window.axios
        .get('/api/impersonate/' + this.currentProvince.id)
        .then((response) => {
          this.setImpersonate(true);
          this.setImpersonatingFrom('provinceSummary');
          window.location.href = response.data.redirect;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.province-summary {
  min-height: 200px;
  height: 1px;
}

.target-indicator-item {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.target-indicator-item > .value {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.no-wrap {
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .v--modal {
    width: auto !important;
    margin-left: 20px;
    margin-right: 20px;
  }

  .target-indicator-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.v--modal {
  margin-top: 10px;
  top: 0px !important;
}

.plan-progress {
  .affix {
    margin-right: 15px;
    width: 33%; // fix wide screen width
  }
}
</style>
